/* 
body {
  height: 100vh;
  margin: 0;
  display: grid;
  place-items: center;
  background: #222;
} */

.module-border-wrap {
  border: double transparent;
  border-radius: 30px;
  background-image: linear-gradient(white, white), linear-gradient(360deg, #138de8 50%, #ff9017 50%);
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 10px;
}

.login_header {
  margin-top: 80px;
  color: black;
  font-weight: 600;
  font-size: 20px;
}

.text-link {
  color: #138de8 !important;
}

a.text-link:hover,
a.text-link:focus {
  color: #138de8 !important;
}

.send_otp_input {
  width: 10%;
  margin-right: 3%;
}

.send_otp_input_div {
  display: flex;
  justify-content: center;
}

.login_title {
  margin: 10px 10px 10px 40px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.ma_btn {
  position: relative;
  margin-right: 3px;
  overflow: hidden;
  color: #fff;
  background-color: #138de8;
  border: 1px solid #e5e5e8;
  padding: 8px 15px 6px 15px;
  border-radius: 5px;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
.ma_btn input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.ma_btn_disabled {
  position: relative;
  margin-right: 3px;
  overflow: hidden;
  color: #fff;
  background-color: #dddddd;
  border: 1px solid #e5e5e8;
  padding: 8px 15px 6px 15px;
  border-radius: 5px;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
.ma_btn_disabled input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  width: 100%;
}

#Voc1pdf {
  left: 889.347px;
  top: 620px;
  position: absolute;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  line-height: 40px;
  margin-top: -14px;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(85, 89, 92, 1);
}

.profile_img_btn_new {
  width: 60px;
  border-radius: 50%;
  height: 60px;
}


.profile_img_btn {
  width: 60px;
  border-radius: 50%;
  height: 60px;
}


.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  border: none;
  background-color: white;
}

.sidebarImagetxt {
height:847px;
  /* background-repeat: no-repeat; */
  /* width: 260px; */
  /* background-size:cover; */
  background-repeat: no-repeat;
  /* width: 260px; */
  background-size: cover;
  background-image: radial-gradient(rgba(19, 141, 232, 0.342), #138de8), url(
'../../assets/img/yamahaIcons/sideBar1.png');
  width: 110%;

  text-align: center;
}

.navbar-vertical .navbar-nav .nav-link.active:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0.25rem;
  color: green;

  bottom: 0.25rem;
  border-left: none;
}
.navbar-vertical.navbar-expand-xs .navbar-nav .nav-link.active:before {
  top: 0.25rem;
  bottom: 0.25rem;
  left: 0;
  right: auto;
  border-left: none !important;
  border-bottom: 0;
}
.navbar-vertical.navbar-expand-sm .navbar-nav .nav-link.active:before {
  top: 0.25rem;
  bottom: 0.25rem;
  left: 0;
  right: auto;
  border-left: none !important;
  border-bottom: 0;
}
.navbar-vertical.navbar-expand-md .navbar-nav .nav-link.active:before {
  top: 0.25rem;
  bottom: 0.25rem;
  left: 0;
  right: auto;
  border-left: none !important;
  border-bottom: 0;
}
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link.active:before {
  top: 0.25rem;
  bottom: 0.25rem;
  left: 0;
  right: auto;
  border-left: none !important;
  border-bottom: 0;
}
.navbar-vertical.navbar-expand-xl .navbar-nav .nav-link.active:before {
  top: 0.25rem;
  bottom: 0.25rem;
  left: 0;
  right: auto;
  border-left: none !important;
  border-bottom: 0;
}
.ct-page-title {
  padding-left: 1.25rem;
  border-left: none !important;
  margin-bottom: 1.5rem;
}

.nav-item {
  /* margin-left:20px; */
}
.navbar-nav .nav-link.active {
  width: 91%;
  height:47px;
  color: #138de8;
  background-color:  white;
  /* background-image: linear-gradient(45deg, rgba(98, 110, 136, 0.62), rgba(53, 79, 128, 0.58)),url("../../assets/img/yamahaIcons/sideBar1.png");  */

  margin-left: 25px;
  border-bottom: 1px solid white;
  
}

.navbar-nav .nav-link {
  color: white;
  width: 94%;
  /* background-color: #fff; */
  margin-left: 25px;
  /* background-color: #138de8; */
  border-bottom-left-radius: 17px;
  /* border-top-right-radius: 17px; */

  border-top-left-radius:17px;
}


.navBordwer {
  margin-top: 70px;
  border-bottom: 1px solid #138de8;
  border-bottom-right-radius: 30px;
}

.mediaViewInfo {
  --web-view-name: Dashboard – 12;
  --web-view-id: Dashboard__12;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
:root {
  --web-view-ids: Dashboard__12;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: none;
}
@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#Dashboard__12 {
  position: absolute;
  width: 1440px;
  height: 754px;
  background-color: rgba(244, 246, 247, 1);
  overflow: hidden;
  --web-animation: fadein 0.3s ease-out;
  --web-action-type: page;
  --web-action-target: Dashboard___11.html;
  cursor: pointer;
  --web-view-name: Dashboard – 12;
  --web-view-id: Dashboard__12;
  --web-scale-on-resize: true;
  --web-enable-deep-linking: true;
}
#Home_v {
  left: 276px;
  top: 96px;
  position: absolute;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  color: rgba(59, 75, 88, 1);
  letter-spacing: -0.24600000381469728px;
}
#Base {
  fill: rgba(255, 255, 255, 1);
}
.Base {
  filter: drop-shadow(2px 8px 7px rgba(141, 130, 153, 0.271));
  overflow: visible;
  position: absolute;
  width: 1230.908px;
  height: 101px;
  left: 232.092px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Ellipse_452 {
  fill: rgba(244, 246, 247, 1);
}
.Ellipse_452 {
  position: absolute;
  overflow: visible;
  width: 25px;
  height: 25px;
  left: 242px;
  top: 255px;
}
#Rectangle_5168 {
  fill: rgba(255, 255, 255, 1);
}
.Rectangle_5168 {
  position: absolute;
  overflow: visible;
  width: 752.83px;
  height: 125.697px;
  left: 276px;
  top: 611.902px;
}
#Rectangle_5169 {
  fill: rgba(255, 255, 255, 1);
}
.Rectangle_5169 {
  position: absolute;
  overflow: visible;
  width: 369.83px;
  height: 125.697px;
  left: 1045px;
  top: 611.902px;
}
#Rectangle_5171_ {
  fill: url(#Rectangle_5171_);
}
.Rectangle_5171_ {
  position: absolute;
  overflow: visible;
  width: 160px;
  height: 38px;
  left: 1255px;
  top: 21px;
}
#Welcome_David {
  left: 276px;
  top: 28px;
  position: absolute;
  overflow: visible;
  width: 172px;
  white-space: nowrap;
  line-height: 42px;
  margin-top: -11px;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  color: rgba(55, 58, 60, 1);
}
#AXKP123B {
  left: 455px;
  top: 35px;
  position: absolute;
  overflow: visible;
  width: 70px;
  white-space: nowrap;
  line-height: 42px;
  margin-top: -15px;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(55, 58, 60, 1);
  letter-spacing: 1.1px;
  text-transform: uppercase;
}
#Group_259 {
  position: absolute;
  width: 24px;
  height: 26px;
  left: 1112px;
  top: 27px;
  overflow: visible;
}
#IconlyLight-outlineNotificatio {
  position: absolute;
  width: 24px;
  height: 24px;
  left: 0px;
  top: 2px;
  overflow: visible;
}
#Notification {
  position: absolute;
  width: 17px;
  height: 20px;
  left: 3.5px;
  top: 2px;
  overflow: visible;
}
#Notification_ {
  fill: rgba(19, 141, 232, 1);
}
.Notification_ {
  overflow: visible;
  position: absolute;
  width: 17px;
  height: 20px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Ellipse_44 {
  fill: rgba(19, 141, 232, 1);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Ellipse_44 {
  position: absolute;
  overflow: visible;
  width: 9px;
  height: 9px;
  left: 12px;
  top: 0px;
}
#IconlyLight-outlineBuy {
  position: absolute;
  width: 24.916px;
  height: 23.02px;
  left: 1206.083px;
  top: 27px;
  overflow: visible;
}
#Buy {
  position: absolute;
  width: 20.833px;
  height: 20.416px;
  left: 0px;
  top: 2.604px;
  overflow: visible;
}
#Buy_bb {
  fill: rgba(19, 141, 232, 1);
}
.Buy_bb {
  overflow: visible;
  position: absolute;
  width: 20.833px;
  height: 20.416px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Ellipse_63 {
  fill: rgba(19, 141, 232, 1);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Ellipse_63 {
  position: absolute;
  overflow: visible;
  width: 9px;
  height: 9px;
  left: 15.917px;
  top: 0px;
}
#IconlyLightHeart {
  position: absolute;
  width: 26px;
  height: 26px;
  left: 1158px;
  top: 27px;
  overflow: visible;
}
#Heart {
  position: absolute;
  width: 20.583px;
  height: 19.5px;
  left: 2.708px;
  top: 3.25px;
  overflow: visible;
}
#Path_33961 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(19, 141, 232, 1);
  stroke-width: 1.5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Path_33961 {
  overflow: visible;
  position: absolute;
  width: 22.125px;
  height: 21.039px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_33964 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(19, 141, 232, 1);
  stroke-width: 1.5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Path_33964 {
  overflow: visible;
  position: absolute;
  width: 3.829px;
  height: 4.376px;
  left: 14.343px;
  top: 4.391px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Left_menu {
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 0.001px;
  top: -0.001px;
  overflow: visible;
}
#Path_18735 {
  fill: rgba(255, 144, 23, 1);
}
.Path_18735 {
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.161));
  overflow: visible;
  position: absolute;
  width: 50px;
  height: 50px;
  left: 25.999px;
  top: 90.001px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Subtraction_3 {
  mix-blend-mode: luminosity;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 19px;
  top: 0px;
  overflow: visible;
}
#Subtraction_2_bl {
  opacity: 0.904;
  fill: url(#Subtraction_2_bl);
}
.Subtraction_2_bl {
  overflow: visible;
  position: absolute;
  width: 240.999px;
  height: 756.999px;
  left: 4px;
  top:30px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  z-index: -10;
}
#Subtraction_2_bmo {
  opacity: 0.904;
  fill: url(#Subtraction_2_bmo);
}
.Subtraction_2_bmo {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 19px;
  top: -154px;
  transform: matrix(1,0,0,1,0,0);
  z-index: -10;
}
#Subtraction_1_bmo {
  opacity: 0.904;
  fill: url(#Subtraction_1_bmo);
}
.Subtraction_1_bmo {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 19px;
  top: -196px;
  transform: matrix(1,0,0,1,0,0);
  z-index: -10;
}
#Subtraction_3_bmo {
  opacity: 0.904;
  fill: url(#Subtraction_3_bmo);
}
.Subtraction_3_bmo {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 19px;
  top: -238px;
  transform: matrix(1,0,0,1,0,0);
  z-index: -10;
}
#Subtraction_3_bl {
  opacity: 0.904;
  fill: url(#Subtraction_3_bl);
}
.Subtraction_3_bl {
  overflow: visible;
  position: absolute;
  width: 240.999px;
  height: 756.999px;
  left: 4px;
  top:75px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  z-index: -10;
}
#Subtraction_2_bt {
  opacity: 0.904;
  fill: url(#Subtraction_2_bt);
}
.Subtraction_2_bt {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 19px;
  top: -82px;
  transform: matrix(1,0,0,1,0,0);
  z-index:-10;
}
#Subtraction_1_bt {
  opacity: 0.904;
  fill: url(#Subtraction_1_bt);
}
.Subtraction_1_bt {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 19px;
  top: -124px;
  transform: matrix(1,0,0,1,0,0);
  z-index:-10;
}
#Subtraction_3_bt {
  opacity: 0.904;
  fill: url(#Subtraction_3_bt);
}
.Subtraction_3_bt {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 19px;
  top: -169px;
  transform: matrix(1,0,0,1,0,0);
  z-index:-10;
}

#Subtraction_2_nn {
  opacity: 0.904;
  fill: url(#Subtraction_2_nn);
}
.Subtraction_2_nn {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
  z-index: -10;
}

#Subtraction_2_fa {
  opacity: 0.904;
  fill: url(#Subtraction_2_fa);
}
.Subtraction_2_fa {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 19px;
  top: -109px;
  transform: matrix(1,0,0,1,0,0);
  z-index: -10;
}
#Subtraction_3_fa {
  opacity: 0.904;
  fill: url(#Subtraction_3_fa);
}
.Subtraction_3_fa {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 19px;
  top: -66px;
  transform: matrix(1,0,0,1,0,0);
  z-index: -10;
}
#Subtraction_4_fa {
  opacity: 0.904;
  fill: url(#Subtraction_4_fa);
}
.Subtraction_4_fa {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 0px;
  top: 40px;
  transform: matrix(1,0,0,1,0,0);
  z-index: -10;
}

#Subtraction_2_a {
		opacity: 0.904;
		fill: url(#Subtraction_2_a);
	}
	.Subtraction_2_a {
		overflow: visible;
		position: absolute;
		width: 219.999px;
		height: 756.999px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
    z-index: -10;
	}

  #Subtraction_2_j {
		opacity: 0.904;
		fill: url(#Subtraction_2_j);
	}
	.Subtraction_2_j {
		overflow: visible;
		position: absolute;
		width: 219.999px;
		height: 756.999px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
	}
  #Subtraction_2_l {
		opacity: 0.904;
		fill: url(#Subtraction_2_l);
	}
	.Subtraction_2_l {
		overflow: visible;
		position: absolute;
		width: 219.999px;
		height: 756.999px;
		left: 0px;
		top: 0px;
		transform: matrix(1,0,0,1,0,0);
    z-index: -10;
	}
#Subtraction_2_byl {
  opacity: 0.904;
  fill: url(#Subtraction_2_byl);
}
.Subtraction_2_byl {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 19px;
  top: -96px;
  transform: matrix(1,0,0,1,0,0);
  z-index: -10;
}
#Subtraction_2_bcs {
  opacity: 0.904;
  fill: url(#Subtraction_2_bcs);
}
.Subtraction_2_bcs {
  overflow: visible;
  position: absolute;
  width: 219.999px;
  height: 756.999px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
  z-index: -10;

}


#Shop {
  opacity: 0.902;
  left: 72.999px;
  top: 164.001px;
  position: absolute;
  overflow: visible;
  width: 32px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}
#My_Vouchers {
  opacity: 0.899;
  left: 72.54px;
  top: 228.001px;
  position: absolute;
  overflow: visible;
  width: 79px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}
#My_Redemption {
  left: 72.644px;
  top: 291.001px;
  position: absolute;
  overflow: visible;
  width: 99px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(253, 253, 253, 1);
}
#Transactions {
  left: 72.999px;
  top: 356.038px;
  position: absolute;
  overflow: visible;
  width: 78px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}
#Membership {
  left: 71.843px;
  top: 418.001px;
  position: absolute;
  overflow: visible;
  width: 79px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}
#Manage_User {
  left: 70.073px;
  top: 480.001px;
  position: absolute;
  overflow: visible;
  width: 82px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}
#Home_bs {
  left: 72.849px;
  top: 99.001px;
  position: absolute;
  overflow: visible;
  width: 39px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: rgba(0, 13, 174, 1);
}
#Address {
  left: 73.072px;
  top: 543.001px;
  position: absolute;
  overflow: visible;
  width: 50px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}
#Settings {
  left: 73.072px;
  top: 605.964px;
  position: absolute;
  overflow: visible;
  width: 51px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: rgba(255, 255, 255, 1);
}
#Group_192 {
  position: absolute;
  width: 100.779px;
  height: 33.568px;
  left: 75.444px;
  top: 23.216px;
  overflow: visible;
}
#REWARDS {
  left: 48.779px;
  top: 23.568px;
  position: absolute;
  overflow: visible;
  width: 53px;
  white-space: nowrap;
  line-height: 23px;
  margin-top: -7px;
  text-align: left;
  font-family: Rockwell;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  color: rgba(253, 253, 253, 1);
  letter-spacing: 0.97px;
}
#Group_190 {
  position: absolute;
  width: 100.342px;
  height: 18.229px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Path_2456 {
  fill: rgba(253, 253, 253, 1);
}
.Path_2456 {
  overflow: visible;
  position: absolute;
  width: 16.705px;
  height: 18.208px;
  left: 0px;
  top: 0.021px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_2457 {
  fill: rgba(253, 253, 253, 1);
}
.Path_2457 {
  overflow: visible;
  position: absolute;
  width: 12.131px;
  height: 18.208px;
  left: 19.389px;
  top: 0.021px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_2458 {
  fill: rgba(253, 253, 253, 1);
}
.Path_2458 {
  overflow: visible;
  position: absolute;
  width: 15.081px;
  height: 18.144px;
  left: 34.386px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_2459 {
  fill: rgba(253, 253, 253, 1);
}
.Path_2459 {
  overflow: visible;
  position: absolute;
  width: 3.027px;
  height: 14.089px;
  left: 70.313px;
  top: 0.021px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_2460 {
  fill: rgba(253, 253, 253, 1);
}
.Path_2460 {
  overflow: visible;
  position: absolute;
  width: 15.157px;
  height: 18.208px;
  left: 52.349px;
  top: 0.021px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_2461 {
  fill: rgba(253, 253, 253, 1);
}
.Path_2461 {
  overflow: visible;
  position: absolute;
  width: 12.13px;
  height: 3.051px;
  left: 70.275px;
  top: 15.179px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_2462 {
  fill: rgba(253, 253, 253, 1);
}
.Path_2462 {
  overflow: visible;
  position: absolute;
  width: 15.156px;
  height: 18.208px;
  left: 85.185px;
  top: 0.021px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#IconlyBoldHome {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 31.999px;
  top: 96.001px;
  overflow: visible;
}
#Home_b {
  position: absolute;
  width: 15.833px;
  height: 16.667px;
  left: 2.083px;
  top: 1.667px;
  overflow: visible;
}
#Home_ca {
  fill: rgba(253, 253, 253, 1);
}
.Home_ca {
  overflow: visible;
  position: absolute;
  width: 15.833px;
  height: 16.667px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#tag {
  position: absolute;
  width: 18.74px;
  height: 18.739px;
  left: 30.629px;
  top: 162.262px;
  overflow: visible;
}
#Path_18644 {
  fill: rgba(253, 253, 253, 1);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 0.30000001192092896px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_18644 {
  overflow: visible;
  position: absolute;
  width: 19.222px;
  height: 19.22px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_18645 {
  fill: rgba(253, 253, 253, 1);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 0.20000000298023224px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_18645 {
  overflow: visible;
  position: absolute;
  width: 4.695px;
  height: 4.724px;
  left: 5.775px;
  top: 8.475px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_18646 {
  fill: rgba(253, 253, 253, 1);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 0.20000000298023224px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_18646 {
  overflow: visible;
  position: absolute;
  width: 4.695px;
  height: 4.689px;
  left: 4.177px;
  top: 10.073px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#IconlyLight-outlineBag_2 {
  position: absolute;
  width: 18.096px;
  height: 18.096px;
  left: 30.95px;
  top: 226.262px;
  overflow: visible;
}
#Bag_2 {
  position: absolute;
  width: 14.703px;
  height: 15.08px;
  left: 1.697px;
  top: 1.319px;
  overflow: visible;
}
#Bag_2_ce {
  fill: rgba(253, 253, 253, 1);
}
.Bag_2_ce {
  overflow: visible;
  position: absolute;
  width: 14.703px;
  height: 15.08px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#IconlyLight-outlineTicket_Star {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 29.999px;
  top: 289.001px;
  overflow: visible;
}
#Ticket_Star {
  position: absolute;
  width: 16.667px;
  height: 15.139px;
  left: 1.667px;
  top: 2.5px;
  overflow: visible;
}
#Ticket_Star_ch {
  fill: rgba(253, 253, 253, 1);
}
.Ticket_Star_ch {
  overflow: visible;
  position: absolute;
  width: 16.667px;
  height: 15.14px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#IconlyLightDocument {
  position: absolute;
  width: 21.049px;
  height: 21.049px;
  left: 29.95px;
  top: 354.665px;
  overflow: visible;
}
#Document {
  position: absolute;
  width: 14.616px;
  height: 16.146px;
  left: 3.289px;
  top: 2.412px;
  overflow: visible;
}
#Stroke_1 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_1 {
  overflow: visible;
  position: absolute;
  width: 7.532px;
  height: 1.199px;
  left: 4.162px;
  top: 11.816px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Stroke_2 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_2 {
  overflow: visible;
  position: absolute;
  width: 7.532px;
  height: 1.199px;
  left: 4.162px;
  top: 8.145px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Stroke_3 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_3 {
  overflow: visible;
  position: absolute;
  width: 3.616px;
  height: 1.199px;
  left: 4.162px;
  top: 4.481px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Stroke_4 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_4 {
  overflow: visible;
  position: absolute;
  width: 15.816px;
  height: 17.346px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#IconlyLightSetting {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 28.999px;
  top: 603.262px;
  overflow: visible;
}
#Setting {
  position: absolute;
  width: 14.822px;
  height: 16.018px;
  left: 2.732px;
  top: 1.898px;
  overflow: visible;
}
#Path_33946 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Path_33946 {
  overflow: visible;
  position: absolute;
  width: 16.226px;
  height: 17.218px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Ellipse_737 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Ellipse_737 {
  position: absolute;
  overflow: visible;
  width: 5.594px;
  height: 5.593px;
  left: 5.217px;
  top: 5.813px;
}
#IconlyLightAdd_User {
  position: absolute;
  width: 21px;
  height: 21px;
  left: 29.999px;
  top: 476.81px;
  overflow: visible;
}
#Add_User {
  position: absolute;
  width: 16.187px;
  height: 16.008px;
  left: 2.406px;
  top: 2.406px;
  overflow: visible;
}
#Stroke_1_cu {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_1_cu {
  overflow: visible;
  position: absolute;
  width: 13.672px;
  height: 6.309px;
  left: 0px;
  top: 10.899px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Stroke_3_cv {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_3_cv {
  overflow: visible;
  position: absolute;
  width: 9.194px;
  height: 9.193px;
  left: 2.239px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Stroke_5 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_5 {
  overflow: visible;
  position: absolute;
  width: 1.2px;
  height: 4.708px;
  left: 14.397px;
  top: 5.18px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Stroke_7 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_7 {
  overflow: visible;
  position: absolute;
  width: 4.779px;
  height: 1.199px;
  left: 12.608px;
  top: 6.934px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#IconlyLightLocation {
  position: absolute;
  width: 22px;
  height: 22px;
  left: 28.999px;
  top: 539.618px;
  overflow: visible;
}
#Location {
  position: absolute;
  width: 14.077px;
  height: 16.748px;
  left: 3.886px;
  top: 2.511px;
  overflow: visible;
}
#Path_33958 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Path_33958 {
  overflow: visible;
  position: absolute;
  width: 15.277px;
  height: 18.004px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Ellipse_740 {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Ellipse_740 {
  position: absolute;
  overflow: visible;
  width: 5.712px;
  height: 5.711px;
  left: 4.782px;
  top: 4.895px;
}
#IconlyLight-outlineShield_Done {
  position: absolute;
  width: 21px;
  height: 21px;
  left: 28.999px;
  top: 415.001px;
  overflow: visible;
}
#Shield_Done {
  position: absolute;
  width: 14.875px;
  height: 17.5px;
  left: 3.063px;
  top: 1.75px;
  overflow: visible;
}
#Shield_Done_c {
  fill: rgba(253, 253, 253, 1);
}
.Shield_Done_c {
  overflow: visible;
  position: absolute;
  width: 14.875px;
  height: 17.5px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#IconlyLightLogout {
  position: absolute;
  width: 15px;
  height: 15px;
  left: 1298px;
  top: 32px;
  overflow: visible;
}
#Logout {
  position: absolute;
  width: 11.898px;
  height: 11.563px;
  left: 1.733px;
  top: 1.732px;
  overflow: visible;
}
#Stroke_1_c {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_1_c {
  overflow: visible;
  position: absolute;
  width: 8.852px;
  height: 12.762px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Stroke_3_c {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_3_c {
  overflow: visible;
  position: absolute;
  width: 8.726px;
  height: 1.199px;
  left: 4.373px;
  top: 5.781px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Stroke_5_c {
  fill: rgba(0, 0, 0, 0);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 1.2000000476837158px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Stroke_5_c {
  overflow: visible;
  position: absolute;
  width: 3.279px;
  height: 5.342px;
  left: 10.068px;
  top: 3.959px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Log_out {
  left: 1321px;
  top: 31px;
  position: absolute;
  overflow: visible;
  width: 52px;
  white-space: nowrap;
  line-height: 42px;
  margin-top: -14.5px;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: rgba(253, 253, 253, 1);
}
#ID2300_Points {
  left: 371px;
  top: 666px;
  position: absolute;
  overflow: visible;
  width: 237px;
  white-space: nowrap;
  line-height: 220px;
  margin-top: -90px;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  color: rgba(19, 141, 232, 1);
}
#ID31_May_21 {
  left: 1071px;
  top: 666px;
  position: absolute;
  overflow: visible;
  width: 179px;
  white-space: nowrap;
  line-height: 220px;
  margin-top: -90px;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 40px;
  color: rgba(19, 141, 232, 1);
}
#MY_REWARD_POINTS {
  left: 302px;
  top: 635px;
  position: absolute;
  overflow: visible;
  width: 172px;
  white-space: nowrap;
  line-height: 21px;
  margin-top: -2.5px;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(55, 58, 60, 1);
}
#Your_membership_expires_on {
  left: 1071px;
  top: 635px;
  position: absolute;
  overflow: visible;
  width: 271px;
  white-space: nowrap;
  line-height: 24px;
  margin-top: -4px;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: rgba(55, 58, 60, 1);
  text-transform: uppercase;
}
#Path_1626_dg {
  fill: url(#Path_1626_dg);
}
.Path_1626_dg {
  overflow: visible;
  position: absolute;
  width: 269.072px;
  height: 38px;
  left: 732px;
  top: 672px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#View_Transaction_history {
  left: 783.536px;
  top: 682.568px;
  position: absolute;
  overflow: visible;
  width: 167px;
  white-space: nowrap;
  line-height: 24px;
  margin-top: -5.5px;
  text-align: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 13px;
  color: rgba(255, 255, 255, 1);
}
#IconlyLight-outlineTicket_Star_di {
  position: absolute;
  width: 49px;
  height: 49px;
  left: 302px;
  top: 666px;
  overflow: visible;
}
#Ticket_Star_dj {
  position: absolute;
  width: 40.397px;
  height: 36.696px;
  left: 4.04px;
  top: 6.06px;
  overflow: visible;
}
#Ticket_Star_dk {
  fill: rgba(19, 141, 232, 1);
}
.Ticket_Star_dk {
  overflow: visible;
  position: absolute;
  width: 40.397px;
  height: 36.696px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Component_412__59 {
  position: absolute;
  width: 107px;
  height: 38px;
  left: 1283px;
  top: 672px;
  overflow: visible;
}
#Path_1626_dm {
  fill: transparent;
  stroke: rgba(200, 81, 204, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_1626_dm {
  overflow: visible;
  position: absolute;
  width: 107px;
  height: 38px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Reactivate {
  left: 18.36px;
  top: 10.784px;
  position: absolute;
  overflow: visible;
  width: 71px;
  white-space: nowrap;
  line-height: 24px;
  margin-top: -5.5px;
  text-align: center;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(200, 81, 204, 1);
}
#Ellipse_774 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(0, 0, 228, 1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Ellipse_774 {
  filter: drop-shadow(7px 7px 13px rgba(0, 0, 0, 0.243));
  position: absolute;
  overflow: visible;
  width: 88px;
  height: 88px;
  left: 1323px;
  top: 313px;
}
#Icon_feather-arrow-right_eb {
  position: absolute;
  width: 15.06px;
  height: 15.06px;
  left: 1339.97px;
  top: 329.97px;
  overflow: visible;
}
#Path_7125 {
  fill: transparent;
  stroke: rgba(0, 0, 228, 1);
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_7125 {
  overflow: visible;
  position: absolute;
  width: 17.06px;
  height: 2px;
  left: 0px;
  top: 7.53px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_7126 {
  fill: transparent;
  stroke: rgba(0, 0, 228, 1);
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_7126 {
  overflow: visible;
  position: absolute;
  width: 9.944px;
  height: 17.889px;
  left: 7.53px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#ID1292 {
  filter: drop-shadow(0px 3px 6px rgba(96, 118, 134, 0.192));
  position: absolute;
  width: 1139px;
  height: 465px;
  left: 276px;
  top: 130px;
  overflow: visible;
}
#ID5216685 {
  filter: drop-shadow(0px 3px 6px rgba(96, 118, 134, 0.192));
  position: absolute;
  width: 1139px;
  height: 465px;
  left: 276px;
  top: 130px;
  overflow: visible;
}
#Group_13 {
  position: absolute;
  width: 1139px;
  height: 462px;
  left: 276px;
  top: 133px;
  overflow: visible;
}
#Mask_Group_12 {
  position: absolute;
  width: 1139px;
  height: 462px;
  left: 0px;
  top: 0px;
  overflow: visible;
}
#Ellipse_3 {
  opacity: 0.127;
  fill: rgba(255, 101, 88, 1);
}
.Ellipse_3 {
  position: absolute;
  overflow: visible;
  width: 8.836px;
  height: 8.836px;
  left: 926.799px;
  top: 253.976px;
}
#Path_18568 {
  opacity: 0.127;
  fill: rgba(255, 101, 88, 1);
}
.Path_18568 {
  overflow: visible;
  position: absolute;
  width: 17.989px;
  height: 18.383px;
  left: 675.434px;
  top: 550.869px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_18569 {
  opacity: 0.127;
  fill: rgba(255, 101, 88, 1);
}
.Path_18569 {
  overflow: visible;
  position: absolute;
  width: 15.949px;
  height: 15.945px;
  left: 394.409px;
  top: 158.557px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_18570 {
  opacity: 0.127;
  fill: rgba(255, 101, 88, 1);
}
.Path_18570 {
  overflow: visible;
  position: absolute;
  width: 15.943px;
  height: 15.942px;
  left: 745.641px;
  top: 162.404px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_18571 {
  opacity: 0.127;
  fill: rgba(255, 101, 88, 1);
}
.Path_18571 {
  overflow: visible;
  position: absolute;
  width: 15.943px;
  height: 15.944px;
  left: 394.415px;
  top: 551.105px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_18572 {
  opacity: 0.127;
  fill: rgba(255, 101, 88, 1);
}
.Path_18572 {
  overflow: visible;
  position: absolute;
  width: 15.943px;
  height: 15.944px;
  left: 878.496px;
  top: 446.537px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Component_412__43 {
  position: absolute;
  width: 146.586px;
  height: 45.284px;
  left: 1225.488px;
  top: 506.794px;
  overflow: visible;
}
#Path_1626_ea {
  fill: url(#Path_1626_ea);
  stroke: rgba(253, 253, 253, 1);
  stroke-width: 3px;
  stroke-linejoin: miter;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_1626_ea {
  overflow: visible;
  position: absolute;
  width: 152.586px;
  height: 51.284px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#GET_PASSES {
  left: 23.793px;
  top: 13.055px;
  position: absolute;
  overflow: visible;
  width: 100px;
  white-space: nowrap;
  text-align: left;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  color: rgba(255, 255, 255, 1);
}
#Ellipse_773 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(19, 141, 232, 1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Ellipse_773 {
  filter: drop-shadow(-7px 7px 13px rgba(168, 100, 95, 0.2));
  position: absolute;
  overflow: visible;
  width: 88px;
  height: 88px;
  left: 319px;
  top: 313px;
}
#Ellipse_775 {
  fill: rgba(255, 255, 255, 1);
  stroke: rgba(19, 141, 232, 1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Ellipse_775 {
  filter: drop-shadow(-7px 7px 13px rgba(168, 100, 95, 0.2));
  position: absolute;
  overflow: visible;
  width: 88px;
  height: 88px;
  left: 1323px;
  top: 313px;
}
#Icon_feather-arrow-right_d {
  position: absolute;
  width: 15.06px;
  height: 15.06px;
  left: 335.97px;
  top: 329.97px;
  overflow: visible;
}
#Path_7125_d {
  fill: transparent;
  stroke: rgba(19, 141, 232, 1);
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_7125_d {
  overflow: visible;
  position: absolute;
  width: 17.06px;
  height: 2px;
  left: 0px;
  top: 7.53px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_7126_ea {
  fill: transparent;
  stroke: rgba(19, 141, 232, 1);
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_7126_ea {
  overflow: visible;
  position: absolute;
  width: 9.944px;
  height: 17.889px;
  left: 0px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Icon_feather-arrow-right_eb {
  position: absolute;
  width: 15.06px;
  height: 15.06px;
  left: 1339.97px;
  top: 329.97px;
  overflow: visible;
}
#Path_7125_ec {
  fill: transparent;
  stroke: rgba(19, 141, 232, 1);
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_7125_ec {
  overflow: visible;
  position: absolute;
  width: 17.06px;
  height: 2px;
  left: 0px;
  top: 7.53px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}
#Path_7126_ed {
  fill: transparent;
  stroke: rgba(19, 141, 232, 1);
  stroke-width: 2px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_7126_ed {
  overflow: visible;
  position: absolute;
  width: 9.944px;
  height: 17.889px;
  left: 7.53px;
  top: 0px;
  transform: matrix(1, 0, 0, 1, 0, 0);
}


.descriptionText{color: rgb(55, 58, 60);
  margin-top:15px;
font-size: 13px;
margin-left: 5px;
/* white-space: nowrap; */
overflow: hidden;
/* text-overflow: ellipsis; */
-webkit-line-clamp: 1;
display: -webkit-box;
-webkit-box-orient: vertical;}


.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  color: #373A3C;
  background-color: #DCEDF6;
  border-bottom: 1px solid #f8f9fe;
  border-right:none;
  border-left:none;
  border-top:none;
  height:40px;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-item.show .nav-link {
  /* color: #373A3C; */
  border-bottom: 1px solid #f8f9fe;
  border-right:none;
  border-left:none;
  border-top:none;
  height:40px;
}

#Subtraction_1_bvq {
  fill: rgba(255,234,232,1);
}
.Subtraction_1_bvq {
  filter: drop-shadow(2px 2px 6px rgba(147, 89, 84, 0.153));
  overflow: visible;
  /* position: absolute; */
  width: 692.804px;
  height: 166.004px;
  left: 291.999px;
  /* top: 223.75px; */
  transform: matrix(1,0,0,1,0,0);
}

#Path_18607_bv {
  fill: transparent;
  stroke: rgba(55,58,60,1);
  stroke-width: 1px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-dasharray: 4;
  stroke-dashoffset: 0;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Path_18607_bv {
  overflow: visible;
  position: absolute;
  width: 1px;
  height: 114.318px;
  left: 544.738px;
  top: 241px;
  transform: matrix(1,0,0,1,0,0);
}

#IconlyLight-outlineTicket_Star_bvx {
  position: absolute;
  width: 32px;
  height: 32px;
  left: 590px;
  top: 300px;
  overflow: visible;
}
#Ticket_Star_bvy {
  position: absolute;
  width: 26.753px;
  height: 24.302px;
  left: 2.676px;
  top: 4.013px;
  overflow: visible;
}
#Ticket_Star_bvz {
  fill: rgba(222,27,27,1);
}
.Ticket_Star_bvz {
  overflow: visible;
  position: absolute;
  width: 26.753px;
  height: 24.302px;
  left: 0px;
  top: 0px;
  transform: matrix(1,0,0,1,0,0);
}

/* #Subtraction_1_bv {
  fill: rgba(236,238,239,1);
  stroke: rgba(236,238,239,1);
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Subtraction_1_bv {
  filter: drop-shadow(2px 2px 6px rgba(96, 118, 134, 0.192));
  overflow: visible;
  position: absolute;
  width: 692.804px;
  height: 166.004px;
  transform: matrix(1,0,0,1,0,0);
} */

#Subtraction_1_bv {
  fill: rgba(236,238,239,1);
}
.Subtraction_1_bv {
  filter: drop-shadow(2px 2px 6px rgba(147, 89, 84, 0.153));
  overflow: visible;
  /* position: absolute; */
  width: 692.804px;
  height: 166.004px;
  left: 291.999px;
  /* top: 223.75px; */
  transform: matrix(1,0,0,1,0,0);
}

#Subtraction_1_bwa {
  fill: rgba(255,234,232,1);
  stroke: #DE1B1B;
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Subtraction_1_bwa {
  filter: drop-shadow(2px 2px 6px rgba(96, 118, 134, 0.192));
  overflow: visible;
  /* position: absolute; */
  width: 692.804px;
  height: 166.004px;
  left: 291.999px;
  /* top: 553.254px; */
  transform: matrix(1,0,0,1,0,0);
}

#Subtraction_1_bla {
  fill: rgba(236,238,239,1);
  stroke: #777777;
  stroke-width: 2px;
  stroke-linejoin: miter;
  stroke-linecap: butt;
  stroke-miterlimit: 4;
  shape-rendering: auto;
}
.Subtraction_1_bla {
  filter: drop-shadow(2px 2px 6px rgba(96, 118, 134, 0.192));
  overflow: visible;
  /* position: absolute; */
  width: 692.804px;
  height: 166.004px;
  left: 291.999px;
  /* top: 553.254px; */
  transform: matrix(1,0,0,1,0,0);
}

#Heart_sb {
  fill: rgba(222,27,27,1);
}
.Heart_sb {
  overflow: visible;
  position: absolute;
  width: 16.667px;
  height: 15.833px;
  left: 170px;
  top: 20px;
  background-color: white;
  border-radius: 50%;
  transform: matrix(1,0,0,1,0,0);
}

#Heart_wb {
  fill: rgba(222,27,27,1);
}
.Heart_wb {
  overflow: visible;
  position: absolute;
  width: 16.667px;
  height: 15.833px;
  left: 510px;
  top: 470px;
  background-color: white;
  border-radius: 50%;
  transform: matrix(1,0,0,1,0,0);
}

#Path_33961_rb {
  fill: rgba(0,0,0,0);
  stroke: rgba(55,58,60,1);
  stroke-width: 1.5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Path_33961_rb {
  overflow: visible;
  position: absolute;
  width: 17.366px;
  height: 16.531px;
  left: 170px;
  top: 20px;
  background-color: white;
  border-radius: 50%;
  transform: matrix(1,0,0,1,0,0);
}



#Path_33961_wb {
  fill: rgba(0,0,0,0);
  stroke: rgba(55,58,60,1);
  stroke-width: 1.5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Path_33961_wb {
  overflow: visible;
  position: absolute;
  width: 17.366px;
  height: 16.531px;
  left: 510px;
  top: 470px;
  background-color: white;
  border-radius: 50%;
  transform: matrix(1,0,0,1,0,0);
}


#Path_33961_w1b {
  fill: rgba(0,0,0,0);
  stroke: rgba(55,58,60,1);
  stroke-width: 1.5px;
  stroke-linejoin: round;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  shape-rendering: auto;
}
.Path_33961_w1b {
  overflow: visible;
  position: absolute;
  width: 17.366px;
  height: 16.531px;
  left: 190px;
  top: 20px;
  background-color: white;
  border-radius: 50%;
  transform: matrix(1,0,0,1,0,0);
}

#Heart_w1b {
  fill: rgba(222,27,27,1);
}
.Heart_w1b {
  overflow: visible;
  position: absolute;
  width: 16.667px;
  height: 15.833px;
  left: 210px;
  top: 20px;
  background-color: white;
  border-radius: 50%;
  transform: matrix(1,0,0,1,0,0);
}


.profileImage{
  max-height: 150px;
  max-width: 150px;
   border-radius: 50%;
    margin: 3px;
    margin-left: 70px;
    height:150px;
    width:150px;
    justify-content: center;
}

.camera_btn{
  position:absolute;
  top:59%;
  cursor: pointer;
color:white;
  left:50%;
}

.camera_btn1{
  position:absolute;
  top:69px;
  cursor: pointer;
color:white;
  left:-90px;
}
.camera_btn1 {


  cursor: pointer;

}
.camera_btn1 {
  margin-left:-8px;
  position: relative;
  margin-right: 3px;
  overflow: hidden;
  color: #fff;
  background-color: #138de8;
  border: 1px solid #e5e5e8;
  padding: 8px 15px 6px 15px;
  border-radius: 50%;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  width: 35px;
  height:35px;
}
.camera_btn1 input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.camera_btn1_disabled {
  position: relative;
  margin-right: 3px;
  overflow: hidden;
  color: #fff;
  background-color: #dddddd;
  border: 1px solid #e5e5e8;
  padding: 8px 15px 6px 15px;
  border-radius: 5px;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
.camera_btn1_disabled input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.camera_background{
  width: 20px;
  border-radius: 50%;
  background: transparent linear-gradient(
180deg
, #138de8 0%, #138de8 51%, #1A18E5 100%) 0% 0% no-repeat padding-box;
  height: 20px;
  padding: 20px;
  margin-left: 149px;
  margin-top:-30px;
  z-index: 1;
}

.camera_btn {


  cursor: pointer;

}
.camera_btn {
  margin-left:-8px;
  position: relative;
  margin-right: 3px;
  overflow: hidden;
  color: #fff;
  background-color: #138de8;
  border: 1px solid #e5e5e8;
  padding: 8px 15px 6px 15px;
  border-radius: 50%;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  width: 35px;
  height:35px;
}
.camera_btn input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.camera_btn_disabled {
  position: relative;
  margin-right: 3px;
  overflow: hidden;
  color: #fff;
  background-color: #dddddd;
  border: 1px solid #e5e5e8;
  padding: 8px 15px 6px 15px;
  border-radius: 5px;
  font-size: 13px;
  display: inline-block;
  cursor: pointer;
  width: 100%;
}
.camera_btn_disabled input[type="file"] {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  width: 100%;
}

.memberDescriptionText{
  color: #373A3C;
  /* margin-top:15px; */
font-size: 13px;
font-weight: 600;
margin-left: 5px;
/* white-space: nowrap; */
overflow: hidden;
/* text-overflow: ellipsis; */
-webkit-line-clamp: 1;
display: -webkit-box;
-webkit-box-orient: vertical;}

.waterMark{
  font-size:12px;
  color:#808080;
}

.css-q4ib0v-MuiPaper-root-MuiDialog-paper{
  width: 360px;
  height: 200px;
  margin-bottom: 400px !important;
  font-style: normal;
}

.closeButtonAlterDialogBox{
  width: 11px;
  height: 11px;
  align-items: flex-end;
  float: right;
  margin-left: 330px;
  margin-top: 10px;
}

.MuiDialog-paperWidthSm{
  margin-bottom: 400px !important;
}

