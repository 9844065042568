.headerstyle {
  /* top: 28px; */
  /* left: 276px;
width: 171px;
height: 24px;
text-align: left; */
  font: normal normal bold 20px/42px 'Open Sans';
  letter-spacing: 0px;
  color: #373a3c;
  opacity: 1;
}

.wrapper {
  display: relative;
}

img {
  /* width: 230px; */
}

.wrapper .glyphicon {
  position: absolute;
  top: 200px;
  left: 40px;
  font-size: 40px;
  color: #138de8;
  border-radius: 50%;
  background-color: white;
}

.wrapper .buttonImageName {
  position: absolute;
  top: 347px;
  right: 90px;
  color: white;
  /* font-size: 40px; */
  background-color: #138de8;
}

.wrapper .glyphiconInside {
  position: absolute;
  top: 260px;
  left: 40px;
  right: 20px;
  font-size: 40px;
  color: #138de8;
  border-radius: 50%;
  background-color: white;
}

.wrapper .glyphiconNotification {
  position: absolute;
  top: 100px;
  left: 40px;
  right: 20px;
  font-size: 40px;
  color: #138de8;
  border-radius: 50%;
  background-color: white;
}

.wrapper .glyphiconVoucher {
  position: absolute;
  top: 60px;
  left: 40px;
  right: 20px;
}

.wrapper .glyphiconVoucherLabel {
  position: absolute;
  top: 90px;
  left: 60px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(1, 0, 2, 1);
}

.wrapper .glyphiconCoupon {
  position: absolute;
  top: 20px;
  left: 43px;
  right: 20px;
}

.wrapper .glyphiconCouponLabel {
  position: absolute;
  top: 90px;
  left: 60px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(1, 0, 2, 1);
}

.wrapper .glyphiconVoucherDivider {
  position: absolute;
  top: 30px;
  left: 260px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(1, 0, 2, 1);
}

.wrapper .glyphiconVoucherText {
  position: absolute;
  top: 53px;
  left: 290px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(1, 0, 2, 1);
}

.wrapper .glyphiconVoucherDiv .glyphiconVoucherPrefix {
  position: absolute;
  top: 73px;
  left: 330px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: rgba(1, 0, 2, 1);
}

.wrapper .glyphiconVoucherDiv .glyphiconVoucherImage {
  position: absolute;
  top: 79px;
  left: 296px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  display: flex;
  height: 27px;
}

.wrapper .glyphiconVoucherDiv .glyphiconVoucherImageNo {
  position: absolute;
  top: 71px;
  left: 280px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: #de1b1b;
  display: flex;
}

.wrapper .glyphiconVoucherDiv .glyphiconVoucherImgNo {
  position: absolute;
  top: 71px;
  left: 280px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: #777777;
  display: flex;
}

.wrapper .glyphiconVoucherExpiryLabel {
  position: absolute;
  top: 73px;
  left: 290px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: #de1b1b;
  display: flex;
}

.wrapper .glyphiconVoucherExpiryLabel {
  position: absolute;
  top: 53px;
  left: 590px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(1, 0, 2, 1);
}

.wrapper .glyphiconVoucherExpiryText {
  position: absolute;
  top: 78px;
  left: 590px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  display: flex;
  color: rgba(1, 0, 2, 1);
}

.wrapper .glyphiconEServiceExpiryLabel {
  position: absolute;
  top: 43px;
  left: 290px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: #de1b1b;
  display: flex;
}

.wrapper .glyphiconEServiceExpiryLabel {
  position: absolute;
  top: 23px;
  left: 590px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(1, 0, 2, 1);
}

.wrapper .glyphiconEServiceExpiryText {
  position: absolute;
  top: 43px;
  left: 590px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  display: flex;
  color: rgba(1, 0, 2, 1);
}

.wrapper .glyphiconEServicePlateNumberLabel {
  position: absolute;
  top: 103px;
  left: 290px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  color: #de1b1b;
  display: flex;
}

.wrapper .glyphiconEServicePlateNumberLabel {
  position: absolute;
  top: 83px;
  left: 590px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: rgba(1, 0, 2, 1);
}

.wrapper .glyphiconEServicePlateNumberText {
  position: absolute;
  top: 103px;
  left: 590px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  display: flex;
  color: rgba(1, 0, 2, 1);
}

.timerCard {
  height: 40px;
  width: 40px;
  text-align: center;
  margin-right: 10px;
}

.timerText {
  margin-top: 6px;
}

.timerType {
  background-color: #138de8;
  color: white;
}

.textHeading {
  font-size: 13px;
  color: #373a3c;
}

.textDescription {
  font-size: 16px;
  color: #373a3c;
  font-weight: 600;
}

.memberPointsLabel {
  font-size: 16px;
  color: #373a3c;
}

.memberPoints {
  font-size: 20px;
  color: #373a3c;
  margin-left: 10px;
}

.productTitle {
  font-size: 13px;
  color: #373a3c;
  font-weight: 600;
  text-align: center;
  line-height: 1;
  /* margin-bottom: 15px; */
  padding-bottom: 10px;
}

.productTitleDetail {
  font-size: 16px;
  color: #373a3c;
  font-weight: 600;
  text-align: left;
}

.productTitleDefinition {
  font-size: 16px;
  color: #373a3c;
  text-align: left;
}

.productQuantity {
  font-size: 18px;
  color: #55595c;
}

.productQuantityLabel {
  color: #55595c;
  font-size: 13px;
}

.productLabel {
  background-color: rgba(220 237 246);
  border-color: 1px solid rgba(19 141 232);
  color: #55595c;
  font-size: 13px;
  padding: 0px 10px;
  border-radius: 2px;
}

.productRPrice {
  font-size: 13px;
  color: #373a3c;
  text-decoration: line-through;
  text-align: center;
  margin-bottom: 0;
}

.productSPrice {
  font-size: 13px;
  color: #373a3c;
  text-align: center;
  margin-bottom: 0;
}

.productCard {
  /* top: 221px; */
  /* left: 301px; */
  width: 210px;
  /* height: 255px; */
  background: #f5f8fa 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 6px #60768631;
  border-radius: 6px;
  opacity: 1;
  margin: 7px;
}

.productWishlistCard {
  /* top: 221px; */
  /* left: 301px; */
  width: 250px;
  height: 310px;
  background: #f5f8fa 0% 0% no-repeat padding-box;
  box-shadow: 2px 3px 6px #60768631;
  border-radius: 6px;
  opacity: 1;
  margin: 7px;
}

.wrapper .glyphiconProductImage {
  position: absolute;
  top: 60px;
  left: 40px;
  right: 20px;
  font-size: 40px;
  color: #138de8;
  border-radius: 50%;
  background-color: white;
}

.my-modal {
  width: 290vw;
  max-width: 90vw;
}

.eventsTitle {
  font-size: 18px;
  color: #373a3c;
  font-weight: 600;
  text-align: center;
}

.membershipExpiryTitle {
  font-size: 18px;
  color: #373a3c;
  /* font-weight: 600; */
  text-transform: uppercase;
}

.membershipExpiryDate {
  font-size: 18px;
  color: #138de8;
  margin-left: 10px;
  /* font-weight: 600; */
}

.membershipCardLayout {
  overflow: hidden;
  position: relative;
  /* background- .color: white; */
  /* background-image: url("../assets/img/yamahaIcons/Group381.png"); */
  background-repeat: no-repeat;
  background-size: cover;
  margin-bottom: 0px;
  margin-top: 30px;
  color: white;
}

.membershipCardSize {
  height: 210px;
}

.memberCardNumber {
  font-size: 23px;
}

.membershipCardFooter {
  font-size: 16px;
  color: #138de8;
  text-align: right;
  cursor: pointer;
}

.membershipCardDefFooter {
  font-size: 16px;
  color: #ff9017;
  text-align: right;
  cursor: pointer;
}

.ticketTitle {
  font-size: 12px;
  color: #373a3c;
}

.ticketText {
  font-size: 12px;
  color: #373a3c;
  font-weight: 600;
}

.ticketTextCapital {
  text-transform: uppercase;
  font-size: 16px;
  color: #373a3c;
  font-weight: 600;
}

.bikeTitle {
  font-size: 12px;
  color: #373a3c;
}

.bikeText {
  font-size: 12px;
  color: #373a3c;
  font-weight: 600;
}

.bikeTextCapital {
  text-transform: uppercase;
  font-size: 12px;
  color: #373a3c;
  font-weight: 600;
}

.bikeNameText {
  color: #373a3c;
  /* margin-top:15px; */
  font-size: 13px;
  font-weight: 600;
  margin-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  /* -webkit-line-clamp: 1;
display: -webkit-box;
-webkit-box-orient: vertical; */
}

.bikeStatusPreApproved {
  margin-left: 20px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #efffef;
  color: #00c851;
  padding: 10px;
  width: 115px;
}

.bikeStatusApproved {
  margin-left: 20px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #efffef;
  color: #00c851;
  padding: 10px;
  width: 115px;
}

.bikeStatusPreOwned {
  margin-left: 20px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #efffef;
  color: #00c851;
  padding: 10px;
  width: 115px;
}

.bikeStatusRejected {
  margin-left: 10px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #FFF2F0;
  color: #FF6558;
  padding: 10px;
  width: 115px;
}

.bikeStatusPending {
  margin-left: 20px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #fcf7e4;
  color: #ee9800;
  padding: 10px;
  width: 115px;
}

.bikeStatusVerified {
  margin-left: 20px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #efffef;
  color: #00c851;
  padding: 10px;
  width: 115px;
}



.bikeStatusAssigned {
  margin-left: 20px;
  font-size: 12px;
  border-radius: 10px;
  background-color: #eaf6fc;
  color: #575dff;
  padding: 10px;
  width: 115px;
}

.headerCard {
  height: 44px;
  margin-left: 20px;
  margin-right: 20px;

  padding: 8px;
}

.memberCardBorder {
  border-bottom: 1px solid #dddd;
}


.wrapper .glyphiconVoucherUsageText {
  position: absolute;
  top: 110px;
  left: 290px;
  right: 20px;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  color: #ff9017;
}

.subscriptionTitle {
  color: rgb(55, 58, 60);
  font-size: 13px;
  margin: 10px;
}

.notificationText {
  white-space: nowrap;
  width: 120%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
}