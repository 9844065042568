 .imageBg{
     background-color: white;
    background-image: url("../../../img/yamahaIcons/GEN\ BLU\ reward\ web\ home\(w\ logo\)-01.svg"); 
    background-repeat: no-repeat;
    background-size:cover; 

.main-content {
    overflow: hidden;
    position: relative;
    // background-color: white;
    // background-image: url("../../../img/yamahaIcons/GEN\ BLU\ reward\ web\ home\(w\ logo\)-01.svg"); 
    // background-repeat: no-repeat;
    background-size:cover; 
    margin-bottom: 0px;
    margin-top: 0px;
    // height:1000vh;

    // -webkit-background-size: cover;
    // -moz-background-size: cover;
    // -o-background-size: cover;
    // background-size: cover;
    // margin-top: 30px;
    // Navbar
    .navbar-top {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        background-color: transparent;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
    }

    // Container
    .container-fluid {
        @include media-breakpoint-up(md) {
            // overflow: hidden;
            // position: relative;
            // background-color: white;
            // background-image: url("../../../img/yamahaIcons/web-background.png"); 
            // background-repeat: no-repeat;
            // background-size:cover; 
            // margin-bottom: 0px;
            // margin-top: 50px;
            padding-left: ($main-content-padding-x + $grid-gutter-width / 2) !important;
            padding-right: ($main-content-padding-x + $grid-gutter-width / 2) !important;
        }
    }
}






// Offsets the main content depending on the sidebar positioning

.navbar-vertical.navbar-expand {

    @each $breakpoint,
    $dimension in $grid-breakpoints {

        &-#{$breakpoint} {

            @include media-breakpoint-up(#{$breakpoint}) {

                // Left
                &.fixed-left + .main-content {
                    margin-left: $navbar-vertical-width;
                } // Right
                &.fixed-right + .main-content {
                    margin-right: $navbar-vertical-width;
                }
            }
        }
    }
}


