
.dms-signup-name-input{
    color: black;
}
.dms-signup-name-input::placeholder {
    color: black;
}
.react-datepicker-wrapper{
    padding: 9.5px 11px;
    border-radius: .375rem;
    color: black;
}
.otp-container {
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    gap: 8px;
    width: auto /* Adjust based on your design */
  }

  .otp-box {
    width: 35px !important;
    height: 26px;
    font-size: 14px;
    border-radius: 3px;
    border:  1px solid rgb(255, 144, 23);
    text-align: center;
  }
  
  .otp-box:focus {
    outline: none;
    border: 1px solid #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.8);
  }
  .dms-signup-otp-header{
    display: flex;
    justify-content: center;
    
  }